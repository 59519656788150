'use client'

import {
  createContext,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import clsx from 'clsx'
import { motion, MotionConfig, useReducedMotion } from 'framer-motion'

import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import { Footer } from '@/components/Footer'
import { GridPattern } from '@/components/GridPattern'
import { Logo, Logomark } from '@/components/Logo'
import { Offices } from '@/components/Offices'
import { SocialMedia } from '@/components/SocialMedia'
import { cn } from '@/lib/utils'
// import { ThemeToggle } from '../ThemeToggle';
import { ThemeToggle } from './ThemeToggle'

const RootLayoutContext = createContext<{
  logoHovered: boolean
  setLogoHovered: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

function XIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path d="m5.636 4.223 14.142 14.142-1.414 1.414L4.222 5.637z" />
      <path d="M4.222 18.363 18.364 4.22l1.414 1.414L5.636 19.777z" />
    </svg>
  )
}

function MenuIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path d="M2 6h20v2H2zM2 16h20v2H2z" />
    </svg>
  )
}

function Header({
  panelId,
  icon: Icon,
  expanded,
  onToggle,
  toggleRef,
  invert = false,
}: {
  panelId: string
  icon: React.ComponentType<{ className?: string }>
  expanded: boolean
  onToggle: () => void
  toggleRef: React.RefObject<HTMLButtonElement>
  invert?: boolean
}) {
  let { logoHovered, setLogoHovered } = useContext(RootLayoutContext)!

  return (
    <Container>
      <div className="flex items-center justify-between">
        <Link
          href="/"
          aria-label="Home"
          onMouseEnter={() => setLogoHovered(true)}
          onMouseLeave={() => setLogoHovered(false)}
        >
          {/* <Logomark
            className="h-8 sm:hidden"
            invert={invert}
            filled={logoHovered}
          /> */}

          <h2
            className={cn(
              invert
                ? 'text-white dark:text-black'
                : 'text-black dark:text-white',
              'font-brand text-6xl font-semibold',
            )}
          >
            gnow
          </h2>
          {/* <Logo
            className="hidden h-16 sm:block"
            invert={invert}
            filled={logoHovered}
          /> */}
        </Link>
        <div className="flex items-center gap-x-8">
          <Button href="/auth/sign-in" invert={invert}>
            Sign in
          </Button>
          <button
            ref={toggleRef}
            type="button"
            onClick={onToggle}
            aria-expanded={expanded ? 'true' : 'false'}
            aria-controls={panelId}
            className={clsx(
              'group -m-2.5 rounded-full p-2.5 transition',
              invert ? 'hover:bg-white/10' : 'hover:bg-neutral-950/10',
            )}
            aria-label="Toggle navigation"
          >
            <Icon
              className={clsx(
                'h-6 w-6',
                invert
                  ? 'fill-white group-hover:fill-neutral-200 dark:fill-black dark:group-hover:fill-neutral-700'
                  : 'fill-neutral-950 group-hover:fill-neutral-700 dark:fill-neutral-100 dark:group-hover:fill-neutral-200',
              )}
            />
          </button>
        </div>
      </div>
    </Container>
  )
}

function NavigationRow({ children }: { children: React.ReactNode }) {
  return (
    <div className="even:mt-px sm:bg-neutral-950 sm:dark:bg-white">
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2">{children}</div>
      </Container>
    </div>
  )
}

function NavigationItem({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) {
  return (
    <Link
      href={href}
      className="group relative isolate -mx-6 bg-neutral-950 px-6 py-10 even:mt-px dark:bg-white sm:mx-0 sm:px-0 sm:py-16 sm:odd:pr-16 sm:even:mt-0 sm:even:border-l sm:even:border-neutral-800 sm:even:pl-16 sm:dark:even:border-neutral-200"
    >
      {children}
      <span className="absolute inset-y-0 -z-10 w-screen bg-neutral-900 opacity-0 transition group-odd:right-0 group-even:left-0 group-hover:opacity-100  dark:bg-neutral-100" />
    </Link>
  )
}

function Navigation() {
  return (
    <nav className="mt-px font-sans text-5xl font-medium tracking-tight text-white dark:text-black">
      <NavigationRow>
        <NavigationItem href="/enrolled/1">My Guides</NavigationItem>
        <NavigationItem href="/library/1?categoryId=development">
          Community Guides
        </NavigationItem>
      </NavigationRow>
      <NavigationRow>
        <NavigationItem href="/credits">Pricing</NavigationItem>
        <NavigationItem href="/auth/sign-in?postLoginTheme=dark">
          Sign In
        </NavigationItem>
      </NavigationRow>
    </nav>
  )
}

function RootLayoutInner({ children }: { children: React.ReactNode }) {
  let panelId = useId()
  let [expanded, setExpanded] = useState(false)
  let openRef = useRef<React.ElementRef<'button'>>(null)
  let closeRef = useRef<React.ElementRef<'button'>>(null)
  let navRef = useRef<React.ElementRef<'div'>>(null)
  let shouldReduceMotion = useReducedMotion()

  useEffect(() => {
    function onClick(event: MouseEvent) {
      if (
        event.target instanceof HTMLElement &&
        event.target.closest('a')?.href === window.location.href
      ) {
        setExpanded(false)
      }
    }

    window.addEventListener('click', onClick)

    return () => {
      window.removeEventListener('click', onClick)
    }
  }, [])

  return (
    <>
      <MotionConfig
        transition={shouldReduceMotion ? { duration: 0 } : undefined}
      >
        {/* <MotionConfig> */}
        <header>
          <div
            className="absolute left-0 right-0 top-2 z-40 pt-14"
            aria-hidden={expanded ? 'true' : undefined}
            // @ts-ignore (https://github.com/facebook/react/issues/17157)
            inert={expanded ? '' : undefined}
          >
            <Header
              panelId={panelId}
              icon={MenuIcon}
              toggleRef={openRef}
              expanded={expanded}
              onToggle={() => {
                setExpanded((expanded) => !expanded)
                window.setTimeout(
                  () => closeRef.current?.focus({ preventScroll: true }),
                )
              }}
            />
          </div>

          <motion.div
            layout
            id={panelId}
            style={{ height: expanded ? 'auto' : '0.5px' }}
            className="relative z-50 overflow-hidden bg-neutral-950 pt-2 dark:bg-vanta-700 dark:bg-white"
            aria-hidden={expanded ? undefined : 'true'}
            // @ts-ignore (https://github.com/facebook/react/issues/17157)
            inert={expanded ? undefined : ''}
          >
            <motion.div layout className="bg-neutral-800 dark:bg-white">
              <div
                ref={navRef}
                className="bg-neutral-950 pb-16 pt-14 dark:bg-white"
              >
                <Header
                  invert
                  panelId={panelId}
                  icon={XIcon}
                  toggleRef={closeRef}
                  expanded={expanded}
                  onToggle={() => {
                    setExpanded((expanded) => !expanded)
                    window.setTimeout(
                      () => openRef.current?.focus({ preventScroll: true }),
                    )
                  }}
                />
              </div>
              <Navigation />
              <div className="relative bg-neutral-950 before:absolute before:inset-x-0 before:top-0 before:h-px before:bg-neutral-800 dark:bg-white dark:before:bg-white">
                <Container>
                  <div className="grid grid-cols-1 gap-y-10 pb-16 pt-10 sm:grid-cols-2 sm:pt-16">
                    <div>
                      <h2 className="font-sans text-base font-semibold text-white/70 dark:text-black">
                        gnow
                      </h2>
                      <Offices
                        invert
                        className="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2"
                      />
                    </div>
                    <div className="sm:border-l sm:border-transparent sm:pl-16">
                      <Link
                        href="terms"
                        className="font-sans text-base font-semibold text-white dark:text-black"
                      >
                        Terms
                      </Link>
                      {/* <ThemeToggle /> */}
                    </div>
                  </div>
                </Container>
              </div>
            </motion.div>
          </motion.div>
        </header>

        <motion.div
          layout
          style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
          className="relative flex flex-auto bg-white pt-14 dark:bg-black"
        >
          <motion.div
            layout
            className="relative isolate flex w-full flex-col py-9 "
          >
            <main className="w-full flex-auto ">{children}</main>
          </motion.div>
        </motion.div>
        {/* <div
        style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
        className=" flex flex-auto  bg-white pt-14"
      >
        <main className="w-full flex-auto">{children}</main>
      </div> */}
      </MotionConfig>
    </>
  )
}

export function RootLayout({ children }: { children: React.ReactNode }) {
  let pathname = usePathname()
  let [logoHovered, setLogoHovered] = useState(false)

  return (
    <RootLayoutContext.Provider value={{ logoHovered, setLogoHovered }}>
      <RootLayoutInner key={pathname}>{children}</RootLayoutInner>
    </RootLayoutContext.Provider>
  )
}
